






















































































































import Vue from 'vue';
import { AuthService } from '@/lib/services';
import { Component } from 'vue-property-decorator';
import { headOrSelf, unwrapError } from '@/lib/helpers';
import Logo from '@/components/Logo.vue';

@Component({
  components: { Logo },
})
export default class Reset extends Vue {
  email: string = '';
  key: string = '';
  password: string = '';
  passwordConfirm: string = '';

  loading: boolean = false;
  completed: boolean = false;

  async mounted(): Promise<void> {
    const query = this.$route.query;
    if (!query) {
      this.$toaster.error('Invalid request');
      await this.$router.push({ name: 'login' });
      return;
    }

    this.email = headOrSelf(query['email']) || '';
    this.key = headOrSelf(query['key']) || '';
    if (!this.email || !this.key) {
      this.$toaster.error('Invalid request');
      await this.$router.push({ name: 'login' });
      return;
    }
  }

  async executeReset(): Promise<void> {
    try {
      this.loading = true;
      await AuthService.resetPassword(this.email, this.key, this.password);
      this.$toaster.success('Password reset!');
      await this.$router.push({ name: 'login' });
    } catch (e) {
      this.$toaster.error(
        this.$tc('messages.error.recover.execute'),
        unwrapError(e),
      );
    } finally {
      this.loading = false;
    }
  }

  get passwordMatch(): boolean {
    return this.password === '' || this.password === this.passwordConfirm;
  }

  get buttonDisabled(): boolean {
    return !(
      this.passwordMatch &&
      this.email &&
      this.password &&
      this.passwordConfirm
    );
  }
}
